html {
  /* Backgrounds/themes/colors */
  --rowhero-blue: #15274b;
  --rowhero-accent: #4674c1;
  --rowhero-highlight: #2d4a84;
  --detail-background: #f3f3f9;

  --white: #FFF;
  --default: #000;

  --white-accent: #f3f3f9;

  --positive: #228b22;
  --negative: #cd3232;

  /* Borders */
  --border-default: #CCC;

  /* Border Radiuses */
  --border-radius-large: 10px;
  --border-radius-small: 4px;

  /* Buttons */
  --button-primary: #de3c23;
  --button-secondary: #4674c1;

  /* Fonts */
  --color-low-emphasis: #666;

  --size-title-medium: 1.25rem;
  --size-title-small: 1rem;
  --size-body-medium: 1rem;
  --size-body-small: 0.875rem;

  /* Margins */
  --margin-medium: 2rem;

  /* Shadow Defaults */
  --shadow-default: #AAA;
  
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: row;
  background-color: var(--detail-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;
}

header {
  background-color: var(--rowhero-blue);

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

#profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.75rem;
}

#profile-image button {
  background-color: transparent;
  border-radius: 100px;
  padding: 0.125rem;
  height: 40px;
  width: 40px;
  margin: 0;
  transition: background-color 250ms;
}

#profile-image img {
  height: 34px;
  width: 34px;
  border-radius: 34px;
  border: 1px solid var(--rowhero-accent);
}

#profile-image button:hover {
  background-color: var(--rowhero-accent);
}

#app-logo {
  max-width: 10rem;
  padding: 0.5rem 0.5rem 1rem;
  height: auto;
}

div.pop-up-menu-container {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

#account-menu {
  display: block;
  position: absolute;
  opacity: 0;
  top: 50px;
  right: 17px;
  background-color: var(--white);
  border-radius: var(--border-radius-small);
  padding: 0.5rem 1rem;
  
  margin: 0.5rem;
  box-shadow: 3px 3px 10px var(--shadow-default);
  z-index: 99;
}

div.pop-up-menu-container.visible {
  display: block;
}

#account-menu.visible {
  animation: fade-in 250ms forwards 1;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

#account-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#account-menu button {
  background-color: var(--white);
  color: var(--color-low-emphasis);
  font-weight: normal;

  padding: 1rem 1rem;
  border-bottom: 1px solid var(--border-default);

  display: flex;
  align-items: center;

  transition: background-color 250ms;
}

#account-menu button:hover {
  background-color: var(--white-accent);
  
}

button svg {
  padding-right: 0.5rem;
}

#body-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
}

#sidebar {
  background-color: var(--rowhero-blue);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 12rem;

  display: flex;
  flex-direction: column;
}

#sidebar h2 {
  color: var(--white);
  margin-top: 0;
  font-size: 1.25rem;
}

#sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar nav a {
  color: var(--white);
  padding: 0.5rem 0.5rem 0.5rem;
  border-radius: var(--border-radius-small);
  
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;


  display: block;

  transition: background-color 250ms;
}

#sidebar nav a:hover {
  background-color: var(--rowhero-highlight);
}

#sidebar nav a.active {
  background-color: var(--rowhero-highlight);
}

#sidebar em {
  color: var(--white);
  padding: 0.5rem 0.5rem 0.5rem;
}

#detail {
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;

  display: flex;
  flex-direction: column;
  flex: 1;
}


h1 {
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}

p.subtitle {
  margin-bottom: var(--margin-medium);
}

.center {
  text-align: center;
}

.margin-bottom {
  margin-bottom: var(--margin-medium);
}

div.settings-container {
  border-radius: var(--border-radius-large);
  background-color: var(--white);
  border: 1px solid var(--border-default);
  padding: 1.5rem 2rem 2rem;
}

div.settings-container h2 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: var(--size-title-medium);
}

form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

input, select {
  border: 1px solid var(--border-default);
  border-radius: var(--border-radius-small);
  padding: 0.5rem;
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: var(--size-body-small);
}

input:invalid {
  border: 1px solid var(--negative);
}

button, input[type="submit"] {
  border: 0px;
  border-radius: var(--border-radius-small);
  padding: 0.5rem 1rem;

  color: var(--white);
  font-weight: bold;

  cursor: pointer;
}

button.primary, input.primary {
  background-color: var(--button-primary);
  font-size: var(--size-title-medium);
  padding: 0.625rem 3rem;
}

button.secondary, input.secondary {
  background-color: var(--button-secondary);
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--margin-medium);

  justify-content: space-between;
  flex: 1;
}

.stat-box {
  padding: 1rem 0;
  border-bottom: 1px solid var(--border-default);

  font-size: var(--size-body-small);
  
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
}

.stat-box > span {
  flex: 1;
}

.stat-box-title {
  color: var(--color-low-emphasis);
}

a.low-emphasis {
  display: block;
  margin-top: 2rem;

  font-size: var(--size-body-medium);
  text-decoration: none;
}

@media (max-width: 800px) {
  #sidebar {
    width: auto;
  }

  #body-container {
    flex-direction: column;
  }

  .title-row, .stat-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .stat-box-value, .title-row button {
    margin-top: 1rem;
  }
}