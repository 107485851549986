.step-progress-bar {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}

.step-progress-bar-step {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.step-progress-bar-step .step-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.75rem;
}

.step-progress-bar-step .line {
  height: 2px;
  background-color: var(--rowhero-accent);
  flex: 1;
}

.step-line .line-hidden {
  background-color: var(--detail-background);
  flex: 1;
}

.step-line .dot-current {
  background-color: white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 5px solid var(--rowhero-accent);
}

.step-line .dot-completed {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--rowhero-accent);
}

.step-line .dot-future {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid var(--rowhero-accent);
}

.step-progress-bar-step .step-title {
  align-self: center;
  font-size: var(--size-title-small);
  color: var(--rowhero-accent);
}

.step-progress-bar-step .step-title.current {
  font-weight: bold;
}