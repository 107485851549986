#plan-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2rem;
}

div.plan-selection-host {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

div.plan-selection {
  background-color: var(--white);
  border: 1px solid var(--rowhero-accent);
  border-radius: 10px;
  border-top-width: 10px;
  height: 100%;
  padding: 1rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.plan-selection h2 {
  margin: 0;

  color: var(--rowhero-accent);
}

div.plan-feature-list {
  margin: 0 1rem 3rem;

  flex: 1;
}

div.plan-feature {
  border-bottom: 2px solid var(--border-default);
  padding: 1rem 0;

  display: flex;
  align-items: center;
  flex: 1;
}

span.plan-feature-check-container {
  width: 40px;

  display: flex;
  align-items: center;
}

svg.plan-feature-check {
  fill: var(--positive);
}
div.plan-feature-content {
  flex: 1
}

div.plan-popular-ribbon-container {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(90deg);
  width: 150px;
  height: 150px;
  overflow: hidden;
}

div.plan-popular-ribbon {
  background-color: #61CE70;
  color: var(--white);

  font-weight: bold;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;

  margin-top: 35px;
  padding: 0.375rem 0;
  width: 200%;
  transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
}

@media (max-width: 800px) {
  #plan-selection-container {
    flex-direction: column-reverse;
  }
}